<template>
  <div class="page">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="detail" service="credit" backlink="/credit/members" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="question">{{ "c_s2p4_text_1" | localize }}</p>
            <div class="form__group">
              <v-text-field
                v-model="price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.price)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('price', price)"
                :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                :hint="
                  ($v.price.$dirty && !$v.price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'c_s1p1_placeholder' | localize"
                append-icon="icon-₪"
                ref="price"
                outlined
              ></v-text-field>
            </div>

            <p class="question">{{ "c_s2p4_text_2" | localize }}</p>
            <div class="form__group">
              <v-autocomplete
                v-model="city"
                :items="cities"
                @blur="$v.city.$touch()"
                @change="checkForm($v.city)"
                :class="{ invalid: $v.city.$dirty && !$v.city.required }"
                item-text="name"
                item-value="value"
                cache-items
                :label="'s2p4_placeholder_2' | localize"
                :placeholder="'s2p4_placeholder_3' | localize"
                append-icon="icon-arrow-down"
                ref="city"
                outlined
              ></v-autocomplete>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              @click="submitHandler"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const credit = VueCookies.get('credit') || {}

export default {
  name: 'Credit_price',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    cities: [],
    price: credit.realty_price || null,
    city: credit.city || null,
    error: null
  }),
  validations: {
    price: { required },
    city: { required }
  },
  methods: {
    submitHandler () {
      this.price = this.parseNumber(this.price)
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      credit.realty_price = this.price
      credit.city = this.city
      this.$cookies.set('credit', credit)
      this.saveServiceInfo('credit', '/credit/mortgage', {
        credit_type: credit.type === 1 ? 1 : 0,
        realty_price: this.price,
        city_id: this.city
      })
    }
  },
  created () {
    this.price = credit.realty_price ? this.formatNumber(credit.realty_price) : null
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
      })
      .catch((e) => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  }
}
</script>
